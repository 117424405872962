<template>
  <CssVar>
      
    <div id="app" class="v-application ma-0">
      <MenuButton/>
      <table style="width:100%; height:100%">
        <tbody>
          <td>
            <span class="diceBox d-flex align-start flex-column ">

              <div @click="dice(1)" class="ma-2">
                <Dice :value="1" id="dice1" :strict="true"/>
              </div>

              <div @click="dice(2)" class="ma-2">
                <Dice :value="2" id="dice2" :strict="true"/>
              </div>
              <div @click="dice(3)" class="ma-2">
                <Dice :value="3" id="dice3" :strict="true"/>
              </div>
              <div @click="dice(4)" class="ma-2">
                <Dice :value="4" id="dice4" :strict="true"/>
              </div>
              <div @click="dice(5)" class="ma-2">
                <Dice :value="5" id="dice5" :strict="true"/>
              </div>
              <div  @click="dice(6)" class="ma-2">
                <Dice :value="6" id="dice6" :strict="true"/>
              </div>
            </span>
          </td>

          <td>
        <span class="miniDiceBox d-flex align-start flex-column">
          <div v-for="(dice,index) in dicesValues" class="ma-1" :key="index" @click="miniDice(index)">
            <Dice  :bodySize="20" :value="dice" :strict="true"/>
          </div>
        </span>
          </td>

          <td class="UsersTable d-flex">
        <UsersTable dicesValue="dicesValue" />

          </td>
        </tbody>

      </table>

    
    </div>
  </CssVar>
</template>

<script>
import Vue from "vue";
import Dice from "./components/Dice.vue";
import CssVar from "./components/CssVar.vue";
import UsersTable from "./components/UsersTable.vue";
import MenuButton from "./components/MenuButton.vue";

export default {
  name: "App",
  components: {
    Dice,
    UsersTable,
    CssVar,
    MenuButton
  },
  beforeCreate() {
    Vue.prototype.$app = this;
  },
  data() {
    return {
      dicesValues:[0,0,0,0,0]
    };
  },
  computed: {
    dicesValue() {
      let result = [
          this.dicesValues[0],
          this.dicesValues[1],
          this.dicesValues[2],
          this.dicesValues[3],
          this.dicesValues[4],
        ];
        return result
    },
  },
    methods:{
      dice(value){
        this.dicesValues.shift();
        this.dicesValues.push(value)
      },
      miniDice(index){
        this.dicesValues.splice(index, 1)
        this.dicesValues.unshift(0)
      }
    }
};
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  margin-top: 60px;
  user-select: none;
  margin: 0px;
  padding: 0px;
}

body{
    background-color: darkolivegreen;

}

.diceBox{
  /* position: absolute; */
  z-index: 10;
  border-radius: 10px;
  background-color: rgba(0,0,0,.6);
}

.UsersTable{
width: 100%;
height: 100%;
}
tbody > td:nth-child(1){
  width: 60px;
}
tbody > td:nth-child(2){
  width: 30px;
}
</style>
