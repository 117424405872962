<template>
  <div class="" :id="id">
    <svg class="diceBody" :width="bodySize" :height="bodySize">
      <rect :width="bodySize" :height="bodySize" fill="ivory"/>
      <circle v-for="(face, i) in dice" :key="i" :cx="face.x" :cy="face.y" :r="pointSize" fill="black" />
    </svg>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      default: 0,
      type: Number,
      validator: function (value) {
        return [0, 1, 2, 3, 4, 5, 6].indexOf(value) !== -1;
      },
    },
    id: {
      type: String,
    },
    
    strict: {
      default: false,
      type: Boolean,
    },
    bodySize: {
      default: 45,
      type: Number,
    },
    pointRatio: {
      default: 9,
      type: Number,
    },
  },
  computed: {
    dice() {
      let dice = {
        lt: { x: this.bodySize / 4, y: this.bodySize / 4 }, //LT
        lm: { x: this.bodySize / 4, y: this.bodySize / 2 }, //LM
        lb: { x: this.bodySize / 4, y: this.bodySize * (3 / 4) }, // LB
        mm: { x: this.bodySize / 2, y: this.bodySize / 2 }, //MM
        rt: { x: this.bodySize * (3 / 4), y: this.bodySize / 4 }, //RT
        rm: { x: this.bodySize * (3 / 4), y: this.bodySize / 2 }, //RM
        rb: { x: this.bodySize * (3 / 4), y: this.bodySize * (3 / 4) }, //RB
      };
      switch (Number(this.value)) {
        case 1:
          return [dice.mm];
        case 2:
          return [dice.lt, dice.rb];
        case 3:
          return [dice.lt, dice.rb, dice.mm];
        case 4:
          return [dice.lt, dice.lb, dice.rt, dice.rb];
        case 5:
          return [dice.lt, dice.lb, dice.rt, dice.rb, dice.mm];
        case 6:
          return [dice.lt, dice.lm, dice.lb, dice.rt, dice.rm, dice.rb];
      }
      return [];
    },
    pointSize() {
      return this.bodySize / this.pointRatio;
    },
  },
  methods: {
//    roll() {
//      this.v = Math.ceil(Math.random() * 6);
//    },
  },
};
</script>
<style scoped>
.diceBody {
  background-color: ivory;
  border-radius: 12%;
  box-shadow: rgba(0,0,0,.7) 2px 2px 3px;
  z-index: 1;
}

.diceButton {
  background-color: rgba(255, 255, 255, 0.3);
  border: solid 2px rgba(255, 255, 255, 0.6);
  position: relative;
  height: 25px;
  width: 25px;
  opacity: .2;
  transition: ease-out 0.5s;
}
.diceButton:hover {
  opacity: 1;
    border: solid 1px rgba(255, 255, 255, 0.9);

}
.diceAddButton {
    transform: translateY(15px) rotate(45deg);


}
.diceAddButton:hover {
  transform: translateY(12px) rotate(45deg);

}
.diceMinusButton {
    transform: translateY(-15px) rotate(45deg);

}
.diceMinusButton:hover {
      transform: translateY(-12px) rotate(45deg);

}
</style>