<template>
  <div :style="cssVar">
    <slot />
  </div>
</template>
<script>
import Vue from "vue";

export default {
  beforeCreate() {
    Vue.prototype.$cssVar = this;
  },
  computed: {
    cssVar() {
      return Object.assign(this.playersColors, this.trHeight);
    },
    playersColors() {
      return {
        "--player1": "red",
        "--player2": "blue",
        "--player3": "purple",
        "--player4": "green",
        "--player5": "cyan",
      };
    },
    trHeight() {
      return { "--trHeight": Math.floor(window.innerHeight / 19.1) + "px" };
    },
  },
};
</script>
<style>
</style>