<template>
        <td>
            <div :class="`Joueur${Number(indexUser)+1}` + ' headUser ' + (active?' active ':' unactive ')">
                <tr :class="'name'+ (active?' active ':' unactive ')" @click="$table.nextUser(indexUser)">{{name}}</tr>
            </div>

            <div :class="`Joueur${Number(indexUser)+1}` + (active?' active ':' unactive ')" v-for="(value,index) in $table.suggest" :key="index">
                <tr 
                    :id="`Joueur${Number(indexUser)+1}` + index" 
                    :class="index + (active?' active ':' unactive ')+ ` ${content[index] !== false?'lock':'suggest'}`" 
                    @click="lock(value, index)">
                        {{ content[index] !== false ? content[index] : value != 0 ? value : '' }}
                </tr>
        </div>
        </td>
</template>
<script>

export default {
    props:{
        name:{ 
            type:String,
            required:true
        },
        active:{
            type:Boolean,
            required:true
        },
        indexUser:{
            type:Number,
            required:true
        }
    },
    mounted(){
        this.$table.usersComponents[this.indexUser] = this
    },
    data(){
        return {
                the1:false,
                the2:false,
                the3:false,
                the4:false,
                the5:false,
                the6:false,
                
                brelan:false,
                full:false,
                carre:false,
                petiteSuite:false,
                grandeSuite:false,
                yams:false,
                chance:false,
            
        }
    },
    methods:{
        restart(){
                this.the1=false
                this.the2=false
                this.the3=false
                this.the4=false
                this.the5=false
                this.the6=false
                
                this.brelan=false
                this.full=false
                this.carre=false
                this.petiteSuite=false
                this.grandeSuite=false
                this.yams=false
                this.chance=false
        },
        lock(value, index){
            if(!this.active)return
            if (this[index]=== false){
                this[index]=value
            }else{
                return this[index]=false
            }
            this.$table.nextUser()
        },
        setTotal1(){
            return this.the1+this.the2+this.the3+this.the4+this.the5+this.the6
        },
        setBonus(){
            if(this.setTotal1()>=62)return 30
            return 0
        },
        setTotal2(){
            return this.setTotal1()+this.setBonus()
        },
        setTotal3(){            
            return this.brelan + this.full + this.carre + this.petiteSuite + this.grandeSuite + this.yams + this.chance 
        },
        SETTOTAL(){
            return this.setTotal2() + this.setTotal3()
        }
    },
    computed: {
        content(){
            return {
                the1:this.the1,
                the2:this.the2,
                the3:this.the3,
                the4:this.the4,
                the5:this.the5,
                the6:this.the6,

                total1:`${this.setTotal1()} ${this.setBonus()?'+ 30':''}` ,

                brelan:this.brelan,
                full:this.full,
                carre:this.carre,
                petiteSuite:this.petiteSuite,
                grandeSuite:this.grandeSuite,
                yams:this.yams,
                chance:this.chance,

                total3:this.setTotal3(),
                TOTAL:this.SETTOTAL(),

            }
        },
    }
}
</script>

<style>
.Joueur1,.Joueur2, .Joueur3, .Joueur4, .Joueur5 {
    border-radius:15px;
}
.Joueur1{
    background-color: var(--player1);
    border-color: var(--player1);
}
.Joueur2{
    background-color: var(--player2);
    border-color: var(--player2);

}
.Joueur3{
    background-color: var(--player3);
    border-color: var(--player3);
}
.Joueur4{
    background-color: var(--player4);
    border-color: var(--player4);
}
.Joueur5{
    background-color: var(--player5);
    border-color: var(--player5);
}

/* ############################################ */

.active{
    box-shadow: rgba(255, 255, 255, 0.301) 0px 0px 5px 2px;
    opacity: .9;
}
.unactive{
    opacity: .7;
}

.active.suggest{
    color:rgb(201, 201, 201);
}
.active.suggest:hover{
    border-style:solid;
    border-width: 2px;
    background-color: rgba(255, 255, 255, 0.541);
    color:black;
}

.headUser{
    transition: .5s;
}
.headUser.active{
    margin-left: .5em;
    margin-right: .5em;
    /* width: 80px; */
}

.suggest.unactive{
    color:transparent;
}


.lock{
    border-style: solid;
    background-color: white;
    color:black;
}

.total1, .total2, .total3, .bonus, .TOTAL, .total1:hover, .total2:hover, .total3:hover, .bonus:hover, .TOTAL:hover{
    border:black 3px;
    background-color: rgb(48, 48, 48);
    color:white;
}

.name{
    color:white;
    background-color: rgba(0, 0, 0, 0.65);
}
.name:hover{
    font-size: 90%;
}
.name.active{
    font-size: 90%;

}


</style>