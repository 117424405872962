<template>
  <div class="MenuButton">
    <button class="MenuButton pa-1" @click="active = !active">
      {{ !active ? "MENU" : "Close" }}
    </button>

    <div v-if="active" class="MenuMenu">
      <input
        v-for="(player, index) in $table.users"
        :key="index"
        class="userName"
        :id="'player' + (Number(index) + 1)"
        type="text"
        :placeholder="player"
        size="10"
        @input="onChange"
      />
      <button class="delButton" @click="$table.delUser()">Supprimer joueur</button>
      <button @click="$table.restart(); active=false">Recommencer</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    onChange(event) {
      this.$table.users[Number(event.target.id.replace("player", "")) - 1] = event.target.value;
      this.$table.users.push("");
      this.$table.users.pop();
    },
  },
};
</script>

<style>
.MenuButton {
  position: absolute;
  display: inline-flex;
  top: 0.2em;
  left: 0.2em;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  z-index: 30;
}
.MenuMenu {
  /* height: 25em; */
  width: 11em;
  top: 0.2em;
  left: 0.2em;
  padding: 0.3em;
  padding-top: 3em;
  color: white;
  z-index: 25;
}
.MenuMenu button{
    padding:.2em;
  background-color: rgba(0, 0, 0, 0.95);

}
.delButton{
    color:red;
    margin-top:2em!important;

}

.userName {
  background-color: rgba(0, 0, 0, 0.521);
  margin: 0.2em;
}
#player1 {
  border-left: solid 6px;
  border-left-color: var(--player1);
  padding-left: 1em;
  color: var(--player1) !important;
}
#player2 {
  border-left: solid 6px;
  border-left-color: var(--player2);
  padding-left: 1em;
  color: var(--player2) !important;
}
#player3 {
  border-left: solid 6px;
  border-left-color: var(--player3);
  padding-left: 1em;
  color: var(--player3) !important;
}
#player4 {
  border-left: solid 6px;
  border-left-color: var(--player4);
  padding-left: 1em;
  color: var(--player4) !important;
}
#player5 {
  border-left: solid 6px;
  border-left-color: var(--player5);
  padding-left: 1em;
  color: var(--player5) !important;
}
</style>