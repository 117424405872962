<template>
    <table>
        <tbody>

            <td class="flex flex-direction: column tableLabels">
                <div><tr class="name">Joueurs</tr></div>

                <tr> <Dice strict :value="1" :bodySize="15"/> </tr>
                <tr> <Dice strict :value="2" :bodySize="15"/> </tr>
                <tr> <Dice strict :value="3" :bodySize="15"/> </tr>
                <tr> <Dice strict :value="4" :bodySize="15"/> </tr>
                <tr> <Dice strict :value="5" :bodySize="15"/> </tr>
                <tr> <Dice strict :value="6" :bodySize="15"/> </tr>

                <tr>T1</tr>

                <tr>Brelan</tr>
                <tr>Full</tr>
                <tr>Carré</tr>
                <tr>Petite Suite</tr>
                <tr>Grande Suite</tr>
                <tr>Yams</tr>
                <tr>Chance</tr>

                <tr>T3</tr>
                <tr>Total</tr>

            </td>

            <UserColumn v-for="(user, index) in users" :key="`Joueur${index+1}`" :name="user" :indexUser="index" :active="index===active"/>  
                <div>
                    <tr class="name newUserButton" @click="newUser(`Joueur${users.length+1}`)">+</tr>
                </div>
        </tbody>
    </table>

</template>
<script>
import Vue from "vue";

import UserColumn from './UserColumn.vue'
import Dice from "./Dice.vue";

export default {
  components: {
    Dice,
    UserColumn
  },
beforeCreate() {
    Vue.prototype.$table = this;
    
  },
data(){
    return {
        users:["Joueur1"],
        active:0,
        usersComponents:[]
    }
},
    computed :{
        count(){
            let result = {1:0,2:0,3:0,4:0,5:0,6:0}
            this.$app.dicesValue.forEach(x=>result[x]++)
            return result
        },

        suggest(){
            return {
                the1:this.the1,
                the2:this.the2,
                the3:this.the3,
                the4:this.the4,
                the5:this.the5,
                the6:this.the6,
                total1:0,
                brelan:this.brelan,
                full:this.full,
                carre:this.carre,
                petiteSuite:this.petiteSuite,
                grandeSuite:this.grandeSuite,
                yams:this.yams,
                chance:this.chance,
                total3:0,
                TOTAL:0

            }
        },

        the1(){
            return this.count[1]
        },
        the2(){
            return this.count[2]*2
        },
        the3(){
            return this.count[3]*3
        },
        the4(){
            return this.count[4]*4
        },
        the5(){
            return this.count[5]*5
        },
        the6(){
            return this.count[6]*6
        },

        brelan(){            
            for (let i in this.count){
                if (this.count[i]>=3)return i*3
            }
            return 0
        },
        full(){
            if((Object.values(this.count).includes(2) && this.brelan )||this.yams) return 25
            return 0
        },
        carre(){
            for (let i in this.count){
                if (this.count[i]>=4)return i*4
            }
            return 0
        },
        petiteSuite(){
            if (Object.values(this.count).join('').match(/[1-5]{4}/))return 30            
            return 0
        },
        grandeSuite(){
            if (Object.values(this.count).join('').match(/[1-5]{5}/))return 40            
            return 0        
        },
        yams(){
            for (let i in this.count){
                if (this.count[i]==5)return 50
            }
            return 0
        },
        chance(){
            return this.$app.dicesValue.reduce((x, y)=>x+=y)
        },
    },
    methods:{
        nextUser(indexUser){
            if (indexUser !== undefined)return this.active = indexUser
            for (let i in this.users){
                if (i==this.active){
                    if (Number(i)+1==this.users.length)i = -1 

                    this.active = Number(i)+1
                    break
                }
            }

        },
        newUser(name){
            this.users.push(name)
        },
        delUser(){
            this.users.pop()
        },
        restart(){
            for (let i in this.$table.usersComponents){
                this.$table.usersComponents[i].restart()
            }
        }
    }


}
</script>
<style scope>

td{
    width: 100%;
}

tr{
    display: flex;
    line-height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

    background-color: rgba(45,45,45,.5);

    width: 60px;
    /* height: 10%; */
    height: var(--trHeight);
    /* height: 29px; */
    font-size: 12px;

    margin-top: 2px;
    margin-bottom: 2px;
    margin-left:auto; 
    margin-right:auto;
    transition: .2s ease;

}

td > div:nth-child(1) > tr, .newUserButton{
    height: 50px;
}

.tableLabels tr{
    background-color: black;
    width: 50px;

}

.newUserButton{
    width: 50px;
    font-size: 25px;
}
.newUserButton:hover{
    font-size: 45px;
}
</style>